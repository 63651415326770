.dietPickerCard {
  display: flex;
  flex-direction: column;
  background-color: var(--cThemeLocal);
  border-radius: var(--radius);
  padding: 0;
  border: none;
  overflow: hidden;
  height: 100%;
  font: inherit;
  text-align: inherit;
  color: var(--cTextInverse);
  cursor: pointer;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  outline: none;
}

.dietPickerCard:hover {
  background-color: var(--cThemeLocalActive);
}

.dietPickerCard:focus-visible {
  box-shadow: var(--focusRingLocal);
}

.dietPickerCard__image {
}

.dietPickerCard__image img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  display: block;
}

.dietPickerCard__body {
  padding: var(--sSpacingLarge);
  display: flex;
  flex-direction: column;
  gap: var(--sSpacingMedium);
}

.dietPickerCard__title {
  font-size: var(--sFontBig);
  font-weight: 500;
}

.dietPickerCard__price {
  font-size: var(--sFontSmall);
}
