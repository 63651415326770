.infoCard {
  display: flex;
  background-color: var(--cSuccessLight);
  color: var(--cSuccess);
  align-items: center;
  padding: var(--sSpacingMedium) var(--sSpacingLarge);
  border-radius: var(--radius);
}

.infoCard_warning {
  background-color: var(--cWarningLight);
  color: var(--cWarning);
}

.infoCard_danger {
  background-color: var(--cDangerLight);
  color: var(--cDanger);
}

.infoCard__icon {
  margin-right: var(--sSpacingMedium);
}

.infoCard__icon svg {
  display: block;
}
