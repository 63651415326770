.menuModal__row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sSpacingMedium);
  margin-top: var(--sSpacingLarge);
}

.menuModal__rowItem {
  flex-grow: 1;
}

.menuModal__day {
  margin-top: var(--sSpacingLarge);
}

.menuModal__dayTitle {
}

.menuModal__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--sSpacingMedium);
}

@media (max-width: 768px) {
  .menuModal__grid {
    grid-template-columns: 1fr;
  }
}

.menuModal__meal {
  padding: var(--sSpacingMedium);
  border-radius: var(--radius);
  border: 1px solid var(--cBorder);
  display: flex;
  gap: var(--sSpacingMedium);
  align-items: center;
}

.menuModal__mealNumber {
  display: block;
  flex-shrink: 0;
}

.menuModal__mealContent {
  display: flex;
  flex-direction: column;
  gap: var(--sSpacingTiny);
}
