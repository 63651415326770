.contentCard {
  box-sizing: border-box;
  padding: var(--sSpacingExtraLarge);
  border-radius: var(--radius);
  background-color: var(--cMainBg);
  display: grid;
  grid-gap: var(--sSpacingExtraLarge);
  --cShade: var(--cShadeRoot);
}

.contentCard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sSpacingLarge);
  flex-wrap: wrap;
}

.contentCard__actions {
  margin-top: calc(-1 * var(--sSpacingSmall));
  margin-bottom: calc(-1 * var(--sSpacingSmall));
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.contentCard__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--sSpacingLarge);
  --span: 2;
}

@media screen and (max-width: 640px) {
  .contentCard__grid {
    grid-template-columns: 1fr;
  }
  .contentCard {
    padding: var(--sSpacingLarge);
    grid-gap: var(--sSpacingLarge);
  }
}

@media screen and (max-width: 360px) {
  .contentCard {
    padding: var(--sSpacingMedium);
    grid-gap: var(--sSpacingMedium);
  }
}

.contentCard__grid > * {
  grid-column: span var(--span);
}

.contentCard__item {
  grid-column: span var(--span);
}

.contentCard__itemTitle.contentCard__itemTitle {
  margin-bottom: var(--sSpacingMedium);
}

@media screen and (max-width: 640px) {
  .contentCard__grid {
    grid-template-columns: 1fr;
  }

  .contentCard__grid > * {
    grid-column: 1;
  }

  .contentCard__item {
    grid-column: 1;
  }
}

/*narrow*/
.contentCard_narrow {
  max-width: 400px;
  width: 100%;
  justify-self: center;
}
