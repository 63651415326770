.radioButton {
  /*position: relative;*/
  height: 100%;
  align-self: stretch;
}

.radioButton__input {
  position: absolute;
  opacity: 0;
}

.radioButton__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: var(--sSpacingLarge);
  padding-block: var(--sSpacingMedium);
  box-shadow: inset 0 0 0 1px var(--cBorder);
  border-radius: var(--radius);
  cursor: pointer;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  box-sizing: border-box;
  height: 100%;
  align-self: stretch;
}

.radioButton_noPadding .radioButton__card {
  padding-inline: 0;
  padding-block: 0;
}

.radioButton__input:checked + .radioButton__card {
  box-shadow: inset 0 0 0 2px var(--cThemeLocal);
}

.radioButton__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioButton__label {
  font-weight: 600;
  word-break: break-word;
}

.radioButton__tick {
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 999em;
  box-shadow: inset 0 0 0 1px var(--cBorder);
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  margin-right: var(--sSpacingMedium);
  flex-shrink: 0;
}

.radioButton__input:checked + .radioButton__card .radioButton__tick {
  box-shadow: inset 0 0 0 5px var(--cThemeLocal);
}

.radioButton__icon {
  display: block;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  margin-right: var(--sSpacingLarge);
}

.radioButton__icon svg {
  display: block;
  /*transition-duration: var(--tGeneric);*/
  /*transition-timing-function: var(--tfStandart);*/
}

.radioButton__input:checked + .radioButton__card .radioButton__icon {
  color: var(--cThemeLocal);
}

.radioButton__description {
  display: block;
  margin-top: var(--sSpacingMedium);
  word-break: break-word;
}

.radioButton__description_icon {
  margin-top: var(--sSpacingSmall);
}

.radioButton__iconOnlyContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
