.actionGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sSpacingMedium);
  flex-wrap: wrap;
}

.actionGroup_end {
  justify-content: flex-end;
}
