.logo {
  display: flex;
  align-items: center;
  gap: var(--sSpacingMedium);
  text-decoration: none;
}

.logo__brand {
  color: var(--cTheme);
  text-decoration: none;
  font-size: var(--sFontH2);
}

.logo img {
  display: block;
}
