.errors {
  display: flex;
  flex-direction: column;
  margin-top: var(--sSpacingTiny);
  gap: var(--sSpacingTiny);
}

.errors__error {
  display: block;
  font-size: var(--sFontSmall);
  color: var(--cDanger);
}
