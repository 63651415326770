.tag {
  padding: var(--sSpacingSmall) var(--sSpacingMedium);
  background-color: var(--cShade);
  border-radius: var(--radius);
}

.tag_success {
  background-color: var(--cSuccessLight);
  color: var(--cSuccess);
}

.tag_warning {
  background-color: var(--cWarningLight);
  color: var(--cWarning);
}

.tag_danger {
  background-color: var(--cDangerLight);
  color: var(--cDanger);
}
