.headerDropdown {
  position: relative;
}

.headerDropdown__dropdownWrapper {
  position: absolute;
  top: calc(100% + var(--sSpacingSmall));
  right: 0;
  background-color: var(--cMainBg);
  box-shadow: 0 0 20px 15px rgba(43, 43, 43, 0.05);
  overflow: hidden;
  border-radius: var(--radius);
  padding-top: var(--sSpacingSmall);
  padding-bottom: var(--sSpacingSmall);
  z-index: var(--zAbove);
}

.headerDropdown__dropdownWrapper a,
.headerDropdown__dropdownWrapper button {
  display: block;
  padding: var(--sSpacingSmall) var(--sSpacingLarge);
  color: var(--cText);
  text-decoration: none;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  border-radius: 0;
  margin: 0;
  background-color: transparent;
  font: inherit;
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  width: 100%;
}

.headerDropdown__dropdownWrapper a:hover,
.headerDropdown__dropdownWrapper button:hover {
  background-color: var(--cShade);
}

.headerDropdown__dropdownWrapper a:focus-visible,
.headerDropdown__dropdownWrapper button:focus-visible {
  background-color: var(--cShade);
  box-shadow: var(--focusRing);
}
