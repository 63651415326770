.optionGroup {
  padding: 0;
  border: 0;
  position: relative;
  margin: 0;
}

.optionGroup__grid {
  display: grid;
  gap: var(--sSpacingMedium);
  align-items: stretch;
  align-content: stretch;
}

.optionGroup__grid_row{
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.optionGroup__legend {
  display: block;
  margin: 0;
  margin-bottom: var(--sSpacingMedium);
}
