.deliveryInfoCard {
  padding: var(--sSpacingLarge) var(--sSpacingLarge);
  background-color: var(--cShade);
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  gap: var(--sSpacingMedium);
}

.deliveryInfoCard__comment {
  padding: var(--sSpacingSmall);
  margin-inline: calc(-1 * var(--sSpacingSmall));
  margin-bottom: calc(-1 * var(--sSpacingSmall));
  border-radius: var(--radiusSmall);
  background-color: var(--cMainBg);
}
