.dietPickerCard {
}

.dietPickerCard__title {
  color: var(--cThemeLocal);
}

.dietPickerCard__titleWrapper {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: var(--sSpacingMedium);
}

.dietPickerCard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--sSpacingMedium);
  flex-wrap: wrap;
}

.dietPickerCard__footer {
  display: flex;
  align-items: flex-end;
  gap: var(--sSpacingMedium);
  /*flex-wrap: wrap;*/
}

.dietPickerCard__footerComment {
  flex-grow: 1;
}
