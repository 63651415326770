.header {
  padding: var(--sSpacingMedium) var(--sSpacingLarge);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__wing {
}

.header__nav {
  display: flex;
  align-items: center;
  gap: var(--sSpacingMedium);
}
