.layout {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100%;
}

.layout__content {
  background-color: var(--cShade);
  padding-block: var(--sSpacingLayout);
  padding-inline: var(--sSpacingMedium);
  padding-bottom: calc(var(--sSpacingLayout) * 2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout__content > * {
  --cShade: var(--cMainBg);
}

.layout__container {
  width: 100%;
  max-width: 1160px;
  display: grid;
  gap: var(--sSpacingLayout);
}
