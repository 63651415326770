.priceGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sSpacingLarge);
  margin: 0;
}

@media screen and (max-width: 640px) {
  .priceGrid {
    grid-template-columns: 1fr;
  }
}

.priceGrid__title {
}

.priceGrid__item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--sSpacingMedium);
}

.priceGrid__item > dt {
  word-break: break-word;
}

.priceGrid__item_total {
  font-weight: 600;
}
