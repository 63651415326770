html,
body {
  height: 100%;
  margin: 0;
  color: var(--cText);
  --cThemeLocal: var(--cTheme);
  --cThemeLocalActive: var(--cThemeActive);
  --focusRing: 0 0 0 3px var(--cThemeLocal);
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#root > * {
  flex-grow: 1;
}
