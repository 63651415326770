.sumTable {
  display: flex;
  flex-direction: column;
  gap: var(--sSpacingMedium);
  margin: 0;
}

.sumTable__listItem {
  display: flex;
  align-items: baseline;
}

.sumTable__listItem:last-child {
  font-weight: bold;
}

.sumTable__listItem dt,
.sumTable__listItem dd {
  margin: 0;
}

.sumTable__listSeparator {
  flex-grow: 1;
  background-image: url("data:image/svg+xml,%3Csvg width='4' height='1' viewBox='0 0 4 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0H3V1H1V0Z' fill='currentColor'/%3E%3C/svg%3E%0A");
  background-repeat: repeat-x;
  height: 1px;
  margin-inline: var(--sSpacingMedium);
}