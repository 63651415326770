.dietSummary {
  background-color: var(--cShade);
  border-radius: var(--radius);
  padding: var(--sSpacingLarge) var(--sSpacingLarge);
  display: flex;
  flex-direction: column;
  gap: var(--sSpacingLarge);
}

.dietSummary * {
  --cShade: var(--cMainBg);
}

.dietSummary__content {
  margin-left: var(--sSpacingSmall);
}

.dietSummary__title {
  color: var(--cThemeLocal);
}

.dietSummary__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--sSpacingLarge);
}

.dietSummary__actions {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-block: calc(-1 * var(--sSpacingSmall));
  margin-right: calc(-1 * var(--sSpacingSmall));
}

.dietSummary__comment {
  padding: var(--sSpacingSmall);
  margin-inline: calc(-1 * var(--sSpacingSmall));
  margin-bottom: calc(-1 * var(--sSpacingSmall));
  border-radius: var(--radiusSmall);
  background-color: var(--cMainBg);
}
