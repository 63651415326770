.modal__wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: var(--zModal);
  box-sizing: border-box;
  display: none;
}

.modal__wrapper_active {
  display: flex;
}

.modal__curtain {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal__container {
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  width: 100%;
  padding: var(--sSpacingLarge);
  align-items: center;
  justify-content: center;
  display: flex;
  --cShade: var(--cShadeRoot);
}

@media (max-width: 360px) {
  .modal__container {
    padding: var(--sSpacingSmall);
  }
}

.modal {
  position: relative;
  background: var(--cMainBg);
  border-radius: var(--radius);
  width: 100%;
  max-width: 720px;
  margin: auto;
  max-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}

.modal_noScroll {
  max-height: none;
}

.modal__close {
  position: absolute;
  top: var(--sSpacingSmall);
  right: var(--sSpacingSmall);
}

.modal__content {
  margin-bottom: var(--sSpacingSmall);
  min-height: 1px;
  display: grid;
  grid-template-rows: 1fr auto;
}

.modal__body {
  padding: var(--sSpacingLarge);
  padding-right: var(--sSpacingMedium);
  margin-right: 4px;
  min-height: 1px;
  overflow: auto;
}

.modal_noScroll .modal__body {
  overflow: visible;
}

.modal__body::-webkit-scrollbar {
  width: 4px;
}

.modal__body::-webkit-scrollbar-thumb {
  border-radius: var(--radius);
  background-color: var(--cBorder);
}

.modal__title {
  padding: var(--sSpacingLarge);
  padding-bottom: 0;
  margin-bottom: 0;
}

.modal_themeTitle .modal__title {
  color: var(--cThemeLocal);
}

.modal__actions {
  padding: 0 var(--sSpacingLarge) var(--sSpacingLarge);
  display: flex;
  justify-content: flex-end;
}
