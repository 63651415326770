.pageSection {
}

.pageSection:not(:last-child) {
  margin-bottom: var(--sSpacingLayout);
}

.pageSection__title {
  margin-bottom: 0;
}

.pageSection__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--sSpacingLarge);
}

.pageSection__content {
  display: flex;
  flex-direction: column;
  gap: var(--sSpacingLarge);
}
