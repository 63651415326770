.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--sSpacingLarge);
}

.formGrid__item {
  --span: 1;
  grid-column: span var(--span);
}

.formGrid__item_footer {
  margin-top: var(--sSpacingMedium);
}

@media screen and (max-width: 640px) {
  .formGrid {
    grid-template-columns: 1fr;
  }

  .formGrid > * {
    grid-column: 1;
  }

  .formGrid__item {
    grid-column: 1;
  }
}
