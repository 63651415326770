.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: var(--sSpacingSmall) var(--sSpacingMedium);
  border: none;
  background: none;
  color: var(--cTextInverse);
  border-radius: var(--radius);
  font: inherit;
  font-weight: 500;
  outline: none;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  cursor: pointer;
  text-decoration: none;
  --cLoaderColor: var(--cShade);
}

.button span {
  line-height: 1.25;
}

.button__content {
  display: flex;
  align-items: center;
}

.button:before {
  display: block;
  content: "";
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  background-color: var(--cThemeLocal);
  opacity: 1;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  border-radius: var(--radius);
}

.button:disabled,
.button.button_disabled {
  cursor: not-allowed;
  color: var(--cTextLight);
}

.button:disabled:before,
.button.button_disabled:before {
  background-color: var(--cShade);
}

.button:not(:disabled):not(.button_disabled):hover:before,
.button:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  background-color: var(--cThemeLocalActive);
}

.button:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  box-shadow: var(--focusRingLocal);
}

.button:not(:disabled):not(.button_disabled):active:before {
  transition-duration: calc(var(--tGeneric) / 4);
  background-color: var(--cThemeLocal);
  left: 2px;
  right: 2px;
}

.button__content > * {
  position: relative;
}

.button__icon:not(:last-child) {
  margin-right: var(--sSpacingSmall);
  margin-left: calc(var(--sSpacingTiny) * -1);
  margin-block: -2px;
}

.button__icon svg {
  display: block;
}

.button_iconOnly {
  padding: var(--sSpacingSmall) var(--sSpacingSmall);
}

.button_fullWidth {
  width: 100%;
}

.button_vertical {
  padding: var(--sSpacingLarge) var(--sSpacingMedium);
}

.button_vertical .button__content {
  flex-direction: column;
}

.button_vertical .button__icon:not(:last-child) {
  margin-right: 0;
}

/* Wireframe */

.button_wireframe {
  color: inherit;
}

.button_wireframe:before {
  background-color: transparent;
  border: 1px solid currentColor;
}

.button_wireframe.button_themeOutline:before {
  background-color: transparent;
  border: 2px solid var(--cThemeLocal);
}

.button_wireframe:disabled,
.button_wireframe.button_disabled {
  cursor: not-allowed;
  color: var(--cTextLight);
}

.button_wireframe:disabled:before,
.button_wireframe.button_disabled:before {
  background-color: var(--cShade);
}

.button_wireframe:not(:disabled):not(.button_disabled):hover:before,
.button_wireframe:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  background-color: var(--cThemeLocalActive);
  border-color: var(--cThemeLocalActive);
}

.button_wireframe:not(:disabled):not(.button_disabled):hover,
.button_wireframe:not(:disabled):not(.button_disabled):global(.focus-visible) {
  color: var(--cTextInverse);
}

.button_wireframe:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  box-shadow: var(--focusRingLocal);
}

.button_wireframe:not(:disabled):not(.button_disabled):active:before {
  transition-duration: calc(var(--tGeneric) / 4);
  background-color: var(--cThemeLocalActive);
  border-color: var(--cThemeLocalActive);
  color: var(--cTextInverse);
  left: 2px;
  right: 2px;
}

.button_wireframe:not(:disabled):not(.button_disabled):active {
  color: var(--cTextInverse);
}

/*text*/
.button_text {
  color: inherit;
}

.button_text:before {
  background-color: transparent;
}

.button_text:disabled,
.button_text.button_disabled {
  cursor: not-allowed;
  color: var(--cTextLight);
}

.button_text:disabled:before,
.button_text.button_disabled:before {
  background-color: transparent;
}

.button_text:not(:disabled):not(.button_disabled):hover:before,
.button_text:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  background-color: var(--cShade);
}

.button_text.button_darkBackground:not(:disabled):not(.button_disabled):hover:before,
.button_text.button_darkBackground:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  background-color: rgba(var(--cMainBgBase), 0.1);
}

.button_text:not(:disabled):not(.button_disabled):global(.focus-visible):before {
  box-shadow: var(--focusRingLocal);
}

.button_text:not(:disabled):not(.button_disabled):active {
  color: inherit;
  background-color: transparent;
}

.button_text:not(:disabled):not(.button_disabled):active:before {
  background-color: var(--cShade);
}

.button_text.button_darkBackground:not(:disabled):not(.button_disabled):active:before {
  background-color: rgba(var(--cMainBgBase), 0.1);
}

/*loader*/
.button__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  visibility: hidden;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.button_loading .button__loader {
  transform: translate(-50%, -50%) scale(0.8);
  opacity: 1;
  visibility: visible;
}

.button_iconOnly .button__loader {
  transform: translate(-50%, -50%) scale(0.3);
}

.button_iconOnly.button_loading .button__loader {
  transform: translate(-50%, -50%) scale(0.5);
}

.button_loading .button__content {
  opacity: 0.2;
}

.button.button_loading:not(.button_wireframe):not(.button_text):disabled:before,
.button.button_loading:not(.button_secondary):not(.button_text).button_disabled:before {
  background-color: var(--cThemeLocal);
}

.button.button_loading:not(.button_wireframe):not(.button_text):disabled,
.button.button_loading:not(.button_secondary):not(.button_text).button_disabled {
  color: var(--cTextInverse);
}
