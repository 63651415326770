.checkbox {
  padding-block: var(--sSpacingTiny);
}

.checkbox__container {
  position: relative;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox__row {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox__tick {
  width: 1.375em;
  height: 1.375em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--cBorder);
  border-radius: var(--radiusSmall);
  margin-right: var(--sSpacingMedium);
  box-sizing: border-box;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  color: var(--cTextInverse);
  flex-shrink: 0;
}

.checkbox__tick svg {
  opacity: 0;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.checkbox:hover .checkbox__tick {
  border-color: var(--cThemeLocal);
}

.checkbox__input:global(.focus-visible) + .checkbox__row .checkbox__tick {
  box-shadow: var(--focusRing);
}

.checkbox__input:checked + .checkbox__row .checkbox__tick {
  background-color: var(--cThemeLocal);
  border-color: var(--cThemeLocal);
}

.checkbox__input:checked + .checkbox__row .checkbox__tick svg {
  opacity: 1;
}
