.input {
}

.input__label {
  display: block;
  margin-bottom: var(--sSpacingSmall);
}

.input__field {
  padding-inline: var(--sSpacingLarge);
  padding-block: var(--sSpacingMedium);
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--cShade);
  font: inherit;
  border: none;
  border-radius: var(--radius);
  outline: none;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.input_disabled .input__field {
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0L0 6' stroke='%23e0e0e0'/%3E%3C/svg%3E");
  background-repeat: repeat;
  background-color: transparent;
  border: 1px solid var(--cShadeActive);
  cursor: not-allowed;
}

.input__field:global(.focus-visible) {
  box-shadow: var(--focusRingLocal);
}

.input_error .input__field {
  background-color: var(--cDangerTransparent);
}
