:root {
  font-family: "Roboto", sans-serif;

  --cSuccess: #4d7d08;
  --cSuccessLight: #f1fae4;
  --base: 85, 72%;
  --baseColor: var(--base), 40%;
  /*--baseColor: 199, 38, 67;*/
  --cTheme: hsl(var(--base), 40%);
  --cThemeActive: hsl(var(--base), 36%);
  /*--cThemeActive: #b61b37;*/
  --cDanger: #e57373;
  --cDangerTransparent: rgba(229, 115, 115, 0.2);
  --cDangerLight: #f8dde0;
  --cWarning: #d9a900;
  --cWarningTransparent: rgba(217, 169, 0, 0.2);
  --cWarningLight: #fff8e1;
  --cMainBg: #fff;
  --cMainBgBase: 255, 255, 255;
  --cShade: #efefef;
  --cShadeRoot: #efefef;
  --cShadeTransparent: hsla(0, 0%, 93.7%, 0.5);
  --cShadeActive: #e0e0e0;
  --cBorder: #dedede;
  --cTextLight: #757575;
  --cText: #2b2b2b;
  --cTextInverse: #fff;
  --border: #ddd;

  --cDietV: #2b8467;
  --cDietVActive: #186a50;
  --cDietVFocusRing: 0 0 0 3px rgba(43, 132, 103, 0.4);
  --cDiet7: #2e7da4;
  --cDiet7Active: #246788;
  --cDiet7FocusRing: 0 0 0 3px rgba(46, 125, 164, 0.4);
  --cDiet12: #4468ae;
  --cDiet12Active: #314d85;
  --cDiet12FocusRing: 0 0 0 3px rgba(68, 104, 174, 0.4);
  --cDiet15: #5c5fa4;
  --cDiet15Active: #47457a;
  --cDiet15FocusRing: 0 0 0 3px rgba(92, 95, 164, 0.4);
  --cDiet18: #6a4a73;
  --cDiet18Active: #4a2d52;
  --cDiet18FocusRing: 0 0 0 3px rgba(106, 74, 115, 0.4);
  --cDiet25: #4f2f62;
  --cDiet25Active: #391c4a;
  --cDiet25FocusRing: 0 0 0 3px rgba(79, 47, 98, 0.4);
  --cDietK15: #efac2a;
  --cDietK15Active: #d08b06;
  --cDietK15FocusRing: 0 0 0 3px rgba(239, 172, 42, 0.4);
  --cDietK25: #eb7442;
  --cDietK25Active: #cd511b;
  --cDietK25FocusRing: 0 0 0 3px rgba(235, 116, 66, 0.4);

  --focusRing: 0 0 0 3px hsla(var(--baseColor), 0.4);
  --focusRingLocal: var(--focusRing);
  --dangerRing: 0 0 0 2px var(--cDanger);

  --radius: 10px;
  --radiusSmall: 7px;

  --tGeneric: 150ms;
  --tfEaseOut: cubic-bezier(0.1, 1, 0.3, 1);
  --tfStandart: cubic-bezier(0.4, 0, 0.2, 1);

  --sFontH1: 2rem; /*32*/
  --sFontH2: 1.5rem; /*24*/
  --sFontH3: 1.125rem; /*18*/

  --sFontSmall: 0.875rem; /*14*/
  --sFontBody: 1rem; /*16*/
  /*--sFontExtraBig: 1.429rem; !*20*!*/
  --sFontBig: 1.125rem; /*18*/
  /*--sFontSmall: 0.875rem; !*14*!*/

  --sSpacingTiny: 4px;
  --sSpacingSmall: 8px;
  --sSpacingMedium: 12px;
  --sSpacingLarge: 20px;
  --sSpacingExtraLarge: 30px;
  --sSpacingLayout: 40px;

  --zAbove: 200;
  --zModal: 500;
}

@media (prefers-reduced-motion: reduce) {
  :root {
    --tGeneric: 0ms;
  }
}

.shadedBg > *,
.shaded > * {
  --cShade: var(--cMainBg);
  --cTextLight: #737373;
}

.standardBg > * {
  --cShade: #efefef;
  --cTextLight: #a5a5a5;
}
