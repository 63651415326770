.textarea {
}

.textarea__wrapper {
  display: grid;
}

.textarea__field {
  resize: none;
  overflow: hidden;
}

.textarea__ghost {
  white-space: pre-wrap;
  visibility: hidden;
}

.textarea__field,
.textarea__ghost {
  padding: var(--sSpacingMedium) var(--sSpacingLarge);
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--cShade);
  border: none;
  border-radius: var(--radius);
  outline: none;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  word-break: break-word;
}

.textarea__field:global(.focus-visible) {
  box-shadow: var(--focusRingLocal);
}

.textarea_error .textarea__field {
  background-color: var(--cDangerTransparent);
}
