.deliveryCalendar {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(var(--numberOfRows, 5), auto);
  --gap: var(--sSpacingMedium);
  gap: var(--gap);
  padding: calc(var(--gap) / 2);
  position: relative;
}

.deliveryCalendar:not(:last-child) {
  margin-bottom: var(--sSpacingMedium);
}

.deliveryCalendar__loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deliveryCalendar__loadingOverlay > * {
  position: relative;
  z-index: 2;
}

.deliveryCalendar__deliveryOverlayBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--cMainBg);
  opacity: 0.9;
  z-index: 1;
}

@media screen and (max-width: 540px) {
  .deliveryCalendar {
    --gap: var(--sSpacingSmall);
  }
}

/*@media screen and (max-width: 420px) {*/
/*  .deliveryCalendar {*/
/*    --gap: var(--sSpacingTiny);*/
/*  }*/
/*}*/

.deliveryCalendar__deliveryOutline {
  grid-row: 1 / -1;
  grid-column: var(--currentColumn) / span 1;
  margin: calc(var(--gap) / -2);
  border-radius: var(--radiusSmall);
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.deliveryCalendar__deliveryOutline:not(.deliveryCalendar__deliveryOutline_hoverZone) {
  border: 1px solid var(--cBorder);
}

.deliveryCalendar__deliveryOutline_hoverZone {
  z-index: 1;
}

.deliveryCalendar__deliveryOutline_hoverZone:hover
  + .deliveryCalendar__deliveryOutline {
  background-color: var(--cShade);
}

.deliveryCalendar__day {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: var(--sSpacingSmall);
  align-items: center;
  min-height: 24px;
  border: 2px solid transparent;
  border-radius: var(--radiusSmall);
  font-weight: 500;
  padding-inline: var(--sSpacingTiny);
}

@media screen and (max-width: 540px) {
  .deliveryCalendar__day {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .deliveryCalendar__day {
    padding-inline: 0;
  }
}

@media screen and (max-width: 360px) {
  .deliveryCalendar__day {
    font-size: var(--sFontSmall);
  }
}

.deliveryCalendar__day_today {
  border-color: var(--cThemeLocal);
}

.deliveryCalendar__day_included {
  background-color: var(--cThemeLocal);
  color: var(--cTextInverse);
}

.deliveryCalendar__day_past {
  background-color: var(--cBorder);
  /*color: var(--cTextInverse);*/
}

.deliveryCalendar__day_header {
  font-weight: 600;
  align-content: end;
}

.deliveryCalendar__header {
  display: flex;
  gap: var(--sSpacingSmall);
  /*flex-wrap: wrap;*/
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

@media screen and (max-width: 640px) {
  .deliveryCalendar__header {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.deliveryCalendar__dayContent {
  text-align: center;
}
