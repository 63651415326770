.footer {
  padding: var(--sSpacingLarge);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--sSpacingLarge);
}

.footer__wing {
  display: flex;
  flex-grow: 1;
}

.footer__nav {
  display: flex;
  flex-direction: column;
  gap: var(--sSpacingSmall);
}

.footer__credentials {
  display: flex;
  flex-direction: column;
  gap: var(--sSpacingSmall);
  align-items: flex-end;
  flex-grow: 1;
}

.footer__credentialsText {
  font-size: var(--sFontSmall);
  color: var(--cTextLight);
  text-align: end;
}

.footer__logos {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer__logos img {
  height: 40px;
}
